import { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [cu_sel,set_sel]=useState(0);
  const params = new URLSearchParams(window.location.search);
  const mch_id = params.get('mch_id');
  const mch_city = params.get('mch_city');
  const mch_type = params.get('mch_type');
  const mch_agent = params.get('mch_agent');


  async function send_data(data,callback)
  {
      const cu_url='https://game.anyidata.com/main.php';
      await fetch(cu_url,{
              method:'POST',
              headers:{
                  'content-type': 'application/json',
                  'Accept-Language':'zh-CN'
              },
              body:JSON.stringify(data)
          })
          .then(res=>res.json())
          .then(data=>{callback(data)})
          .finally()     
  }
  function Pay_list(props)
  {
    var my_class='pay_list';
    if(props.tehui=='true')
    {
      my_class=my_class+' tehui';
    }
    if(cu_sel==props.id)
    {
      my_class=my_class+' pay_list_sel';
    }

    return(
        <div onClick={()=>{set_sel(props.id)}} className={my_class}>
          <span>￥{props.money}元<br></br><span className='money_txt'>{props.time}</span></span> 
        </div>
    )
  }

  function run_pay(money,mch_id,mch_city,mch_type,mch_agent)
  {
    if(mch_id=='' || mch_id==null || mch_city==null || mch_type==null || mch_agent==null)
    {
      alert('错误，请重新扫码进入');
      return;
    }
    window.location.href='https://game.anyidata.com/wxpay/pay/jsapi.php?money='+money+'&mch_id='+mch_id+'&mch_city='+mch_city+'&mch_type='+mch_type+'&mch_agent='+mch_agent;

  }

  function Pay_box()
  {
    let data={};
    data['A']={};
    data['B']={};
    data['C']={};
    data['A']['A']=[15.8,26.8,47.8,83.8];
    data['A']['B']=[9.9,17.8,31.8,55.5];
    data['B']['A']=[9.9,17.8,31.8,55.5];
    data['B']['B']=[7.8,13.8,23.8,43.8];
    data['C']['A']=[7.8,13.8,23.8,43.8];
    data['C']['B']=[9.9,19.9,38.8,68.8];


    let cu_price=data[mch_city][mch_type];
    return(
        <>
            <Pay_list id={0} money={cu_price[0]} time="30分钟" />
            <Pay_list  id={1} money={cu_price[1]} time="60分钟" />
            <Pay_list  id={2} money={cu_price[2]} time="2小时" />
            <Pay_list  id={3} money={cu_price[3]} tehui="true" time="4小时" />
        </>
    )
  }


  return (
    <div className='main_back'>
        <div className='logo'></div>
        <span className='mch_txt'>设备码：{mch_id}</span>
        <div className='money_list'>
            <Pay_box />
        </div>
        {cu_sel>=0?<div onClick={()=>{run_pay(cu_sel,mch_id,mch_city,mch_type,mch_agent)}} className='payok'>支 付</div>:''}
   
    </div>
  );
}

export default App;
